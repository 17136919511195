import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { alpha, styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import FolderIcon from '@mui/icons-material/Folder';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import PasswordIcon from '@mui/icons-material/Password';
import BadgeIcon from '@mui/icons-material/Badge';

const iconsMap = {
  HomeIcon: HomeIcon,
  ContactIcon: ContactSupportIcon,
  DashboardIcon: DashboardIcon,
  ModuleIcon: ChromeReaderModeIcon,
  DescriptionIcon: ArticleRoundedIcon,
  FolderIcon: FolderIcon,
  SmartphoneIcon: SmartphoneIcon,
  PasswordIcon: PasswordIcon,
  BadgeIcon: BadgeIcon,
};

const Item = styled(
  function Item({ component: Component = 'div', ...props }) {
    return <Component {...props} />;
  },
  {
    shouldForwardProp: (prop) => prop !== 'depth' && prop !== 'hasIcon' && prop !== 'subheader',
  },
)(({ theme, hasIcon, depth, subheader }) => {
  const color = {
    color: theme.palette.text.secondary,
    ...(depth === 0 && {
      color: theme.palette.text.primary,
    }),
    ...(subheader && {
      color: theme.palette.grey[600],
    }),
  };

  return {
    ...theme.typography.body2,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    outline: 0,
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    justifyContent: 'flex-start',
    fontWeight: theme.typography.fontWeightMedium,
    transition: theme.transitions.create(['color', 'background-color'], {
      duration: theme.transitions.duration.shortest,
    }),
    fontSize: theme.typography.pxToRem(14),
    textDecoration: 'none',
    paddingLeft: 31 + (depth > 1 ? (depth - 1) * 10 : 0),
    ...color,
    ...(subheader && {
      marginTop: theme.spacing(1),
      textTransform: 'uppercase',
      letterSpacing: '.08rem',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(11),
    }),
    ...(hasIcon && {
      paddingLeft: 2,
    }),
    '&.active.app-drawer-active': {
      color: theme.palette.mode === 'dark' ? theme.palette.primary[300] : theme.palette.primary[600],
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : theme.palette.primary[50],
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
        ),
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
      '&.Mui-focusVisible': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity,
        ),
      },
    },
    '& .MuiChip-root': {
      marginTop: '2px',
    },
    ...(!subheader && {
      '&:hover': {
        color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.common.black,
        backgroundColor:
          theme.palette.mode === 'dark' ? alpha(theme.palette.primaryDark[700], 0.4) : theme.palette.grey[50],
        '@media (hover: none)': {
          color: color.color,
          backgroundColor: 'transparent',
        },
      },
    }),
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.action.focus,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 3,
      paddingBottom: 3,
    },
    '& .ItemButtonIcon': {
      marginLeft: 'auto !important',
      marginRight: '5px',
      color: theme.palette.primary.main,
    },
    '&:hover .ItemButtonIcon': {
      color: theme.palette.text.primary,
      '@media (hover: none)': {
        color: theme.palette.primary.main,
      },
    },
  };
});

const ItemButtonIcon = styled(KeyboardArrowRightRoundedIcon, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open }) => ({
  fontSize: '1rem',
  transform: open && 'rotate(90deg)',
}));

const StyledLi = styled('li', { shouldForwardProp: (prop) => prop !== 'depth' })(({ theme, depth }) => ({
  display: 'block',
  padding: depth === 0 ? theme.spacing(1, '10px', 0, '10px') : '2px 0',
}));

function DeadLink(props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { activeClassName, noLinkStyle, ...other } = props;
  return <div {...other} />;
}

DeadLink.propTypes = {
  activeClassName: PropTypes.any,
  noLinkStyle: PropTypes.any,
};

export default function AppNavDrawerItem(props) {
  const location = useLocation();
  const { children, depth, icon, linkProps, onClick, subheader, title, to, parentRoute, ...other } = props;
  const expandable = !!parentRoute;
  const [open, setOpen] = React.useState(false);
  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }

    if (expandable && !subheader) {
      event.preventDefault();
      setOpen((oldOpen) => !oldOpen);
    }
  };

  React.useEffect(() => {
    setOpen(location.pathname.includes(parentRoute));
  }, [location.pathname]);

  const hasIcon = icon && iconsMap[icon];
  const IconComponent = hasIcon ? iconsMap[icon] : null;
  const iconProps = hasIcon ? { fontSize: 'small', color: 'primary' } : {};
  const iconElement = hasIcon ? (
    <Box
      component="span"
      sx={{
        '& svg': { fontSize: (theme) => theme.typography.pxToRem(16.5) },
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        marginRight: 1.5,
        py: '2px',
      }}
    >
      <IconComponent {...iconProps} />
    </Box>
  ) : null;

  return (
    <StyledLi {...other} depth={depth}>
      <Item
        component={subheader ? DeadLink : NavLink}
        depth={depth}
        hasIcon={hasIcon}
        to={to}
        subheader={subheader}
        className={expandable ? null : 'app-drawer-active'}
        onClick={handleClick}
        {...linkProps}
      >
        {iconElement}
        {title}
        {expandable && !subheader && <ItemButtonIcon className="ItemButtonIcon" open={open} />}
      </Item>
      {expandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      ) : (
        children
      )}
    </StyledLi>
  );
}

AppNavDrawerItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.string,
  linkProps: PropTypes.object,
  onClick: PropTypes.func,
  openImmediately: PropTypes.bool,
  subheader: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
