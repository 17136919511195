import React from 'react';
import useClient from 'infrastructure/hooks/useClient';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AppLayout } from 'components/Layouts';
import InitializeConnector from 'components/InitializeConnector';
import ErrorBoundary from 'pages/Errors/ErrorBoundary';
import Error403 from 'pages/Errors/Error403';
import Error404 from 'pages/Errors/Error404';
import Admin from 'pages/Admin';
import Contact from 'pages/Contact';
import Readers from 'pages/Readers';
import CardInfo from 'pages/CardInfo';
import FileExplorer from 'pages/FileExplorer';
import Partners from './pages/Partners/Partners';
import Login from './pages/Login/Login';
import Truststore from 'pages/Truststore';
import SmartID from 'pages/SmartID';
import TruststoreCertificateDetail from 'pages/Truststore/components/TruststoreCertificateDetail';

const AppSetup = () => {
  const { client } = useClient();

  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          <Route path="403" element={<Error403 />} />
          <Route path="404" element={<Error404 />} />
          <Route path="/" element={<AppLayout />}>
            <Route index element={<Navigate to="app" replace />} />
            {/* Only child routes will be rendered when version check is successfull. */}
            <Route path="app" element={<InitializeConnector />}>
              <Route index element={<Navigate to="readers" replace />} />
              <Route path="readers" element={<Readers />} />
              <Route path="truststore" element={<Truststore />} />
              <Route path="truststore/certificate" element={<TruststoreCertificateDetail />} />
              <Route path="card-info" element={<CardInfo />} />
              <Route path="admin" element={<Admin />} />
              <Route path="file-explorer" element={<FileExplorer />} />
            </Route>
            <Route path="smart-id" element={<SmartID />} />
            <Route path="login" element={<Login />} />
            <Route path="partners" element={<Partners />} />
            {client.showContactPage && <Route path="contact" element={<Contact />} />}
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Route>
        </Routes>
      </Router>
    </ErrorBoundary>
  );
};

export default AppSetup;
