import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import config from 'client/config';
import Styled from './Dropzone.style';

const Dropzone = ({ label, onFileAccepted }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const [myFiles, setMyFiles] = React.useState([]);

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
      onFileAccepted(acceptedFiles[0]);
    },
    [myFiles],
  );

  const onDropRejected = (errors) => {
    const error = errors[0].errors[0];

    if (error.code === 'file-too-large') {
      enqueueSnackbar(
        t('notification.fileToLarge', 'The file is too large, {limit}MB is the maximum allowed size', {
          limit: parseInt(config.maxFileUploadSize / 1048576),
        }),
        { variant: 'warning' },
      );
      return;
    }

    enqueueSnackbar(error.message, { variant: 'warning' });
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxSize: config.maxFileUploadSize,
    onDropRejected,
    accept: { 'application/pdf': [] },
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    onFileAccepted(null);
  };

  const getDefaultProps = React.useMemo(() => {
    if (myFiles.length > 0) {
      return {};
    }

    return getRootProps({ isFocused, isDragAccept, isDragReject });
  }, [myFiles]);

  return (
    <Styled theme={theme} {...getDefaultProps}>
      <input {...getInputProps()} />
      {myFiles.length === 0 && (
        <div className="preview-content">
          <p>{label || t('dragAndDrop.defaultTitle', 'Drag and drop the pdf file here, or click to select.')}</p>
          <CloudUploadIcon fontSize="large" />
        </div>
      )}
      {myFiles.length > 0 && (
        <div className="accepted-content">
          {myFiles.map((file) => (
            <Stack key={file.path} direction="row" spacing={1} alignItems="center">
              <IconButton aria-label={t('action.delete', 'delete')} color="secondary" onClick={removeFile(file)}>
                <DeleteIcon />
              </IconButton>
              <Typography variant="body2">{file.path}</Typography>
            </Stack>
          ))}
        </div>
      )}
    </Styled>
  );
};

Dropzone.defaultProps = {
  onFileAccepted: () => false,
};

export default Dropzone;
